var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutManager',[_c('div',{staticClass:"ml-main w-100",attrs:{"id":"my-achievements"}},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"ma-title"},[_c('div',{staticClass:"d-block d-md-none d-lg-none"},[_vm._v(" "+_vm._s(_vm.$t("general.menu.my_learning"))+" ")]),_c('div',{staticClass:"d-none d-md-block d-lg-block"},[_vm._v(" "+_vm._s(_vm.$t("general.menu.my_achievements"))+" ")])]),(_vm.isLoading)?_c('div',{staticClass:"ml-main__loader"},[_c('Loader')],1):_c('div',{class:[
            'd-none',
            { 'd-md-block d-lg-block': _vm.certificatesList.length > 0 },
            {
              'd-md-flex d-lg-flex align-items-center justify-content-center':
                _vm.certificatesList.length === 0
            }
          ]},[(_vm.certificatesList.length > 0)?_c('div',{staticClass:"ld-sec__achievements"},_vm._l((_vm.certificatesList),function(c,i){return _c('AchievementsCard',{key:i,attrs:{"credential":c,"index":i},on:{"click":function($event){return _vm.navigateTo('my-achievement-details', c.course_key_string)}}})}),1):_c('div',{staticClass:"achievement-empty-sec"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/not-found/achievement-not-found.svg"),"alt":"not found","width":"auto","height":"auto"}}),_c('div',{staticClass:"empty-sec-title w-50 mx-auto"},[_vm._v(" "+_vm._s(_vm.$t("my_achievements.empty.title"))+" ")]),_c('LxpButton',{attrs:{"variant":"outline-primary","className":"achievement-empty-sec__btn"},on:{"click":function($event){return _vm.$router.push({ path: '/course-discovery' })}}},[_vm._v(_vm._s(_vm.$t("my_achievements.empty.button")))])],1)])]),_c('div',{staticClass:"d-block d-md-none d-lg-none"},[_c('div',{staticClass:"ld-sec__tabs mt-0"},[_c('b-tabs',{staticClass:"d-block d-md-none",attrs:{"content-class":"mt-3"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{attrs:{"title":"My programmes"},on:{"click":function($event){return _vm.navigateTo('my-programmes')}}}),_c('b-tab',{staticClass:"active",attrs:{"title":"My Achievements"}},[(_vm.certificatesList.length > 0)?_c('div',{staticClass:"ld-sec__achievements"},[_c('b-row',_vm._l((_vm.certificatesList),function(c,i){return _c('b-col',{key:i,attrs:{"cols":"12"}},[_c('AchievementsCard',{attrs:{"credential":c,"index":i},on:{"click":function($event){return _vm.navigateTo(
                            'my-achievement-details',
                            c.course_key_string
                          )}}})],1)}),1)],1):_c('div',{staticClass:"achievement-empty-sec"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/not-found/achievement-not-found.svg"),"alt":"not found","width":"auto","height":"auto"}}),_c('div',{staticClass:"empty-sec-title w-50 mx-auto"},[_vm._v(" "+_vm._s(_vm.$t("my_achievements.empty.title"))+" ")]),_c('b-button',{staticClass:"btn ob-btn empty-sec-button",on:{"click":function($event){return _vm.navigateTo('course-discovery')}}},[_vm._v(_vm._s(_vm.$t("my_achievements.empty.button")))])],1)])])],1)],1)])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }